<template>
  <div class="skypes">
    <h1>Конференции по группам</h1>
    <a class="btn btn-big" :href="conf.url" target="_blank" v-for="conf in skypes.confs"
       :key="'k-' + conf.id">{{ conf.name }}</a>
    <router-link class="btn btn-block" :to="{name:'skypes-day', params: { id: block.id }}"
                 v-for="block in skypes.blocks" :key="'b-' + block.id">{{ block.name }}</router-link>
    <router-link class="back"  :to="{name:'home'}">Вернуться</router-link>
  </div>
</template>

<script>
  import {mapState} from "vuex";

  export default {
    name: "Skypes",
    created() {
      document.title = "Конференции по группам";
      this.$store.dispatch('getSkypes');
    },
    computed: {
      ...mapState({
        skypes: state => state.skypes
      })
    },

  }
</script>

<style scoped lang="less">
  @import "../assets/vars";

  .skypes {
    .btn {
      display: block;
      background-color: @color-h;
      color: @color-l;
      text-align: center;
      text-decoration: none;
      font-size: 1.5rem;

      &.btn-big {
        padding: 1rem;
        margin-bottom: 1rem;
        font-size: 2rem;
      }

      &.btn-block {
        padding: .5rem;
        margin-bottom: 1rem;
      }
    }

    .back {
      text-align: right;
      color: @color-h;
      display: block;
      text-decoration: none;
    }
  }
</style>
